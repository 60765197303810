import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import CallToAction from '../CallToAction';

export default function Welcome({ title, body, bullets, image }) {
  const WelcomeStyles = styled.section`
    width: 100vw;
    background: var(--grey);
    display: grid;
    grid-template-columns: 1fr 1fr;

    .welcome-image {
      background: url('${image}');
      background-position: center;
      background-size: cover;
    }

    .welcome-content {
      padding: 60px 30px;
    }

    h2 {
      font-size: 28px;
      text-transform: uppercase;
      margin-top: 0;
      color: var(--heading);
    }

    .hr {
      width: 15%;
      background-color: var(--accent);
      height: 2px;
      margin-bottom: 30px;
    }

    p {
      line-height: 32px;
      font-size: 16px;
      color: #666;
      margin: 0;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 30px 0;
    }

    @media only screen and (max-width: 780px) {
      grid-template-columns: 1fr;
      .welcome-image {
        height: 400px;
      }
    }
  `;

  return (
    <WelcomeStyles>
      <div className="welcome-image" />

      <div className="welcome-content">
        <h2>{title || 'title'}</h2>

        <div className="hr" />

        <p>{body}</p>

        {bullets ? (
          <ul>
            {bullets.map((bullet) => (
              <li key={`${new Date().getMilliseconds}-${bullet}`}>
                <p>{bullet}</p>
              </li>
            ))}
          </ul>
        ) : (
          ''
        )}

        <Link to="/contact">
          <CallToAction>Get In Touch</CallToAction>
        </Link>
      </div>
    </WelcomeStyles>
  );
}

Welcome.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  bullets: PropTypes.array,
  image: PropTypes.string.isRequired,
};
