import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AiFillStar } from 'react-icons/ai';
import Quote from '../../images/Quote.svg';

const ReviewStyles = styled.section`
  width: 100vw;
  padding: 30px 0;
  background-color: #fafafa;

  .heading {
    text-align: center;
    padding-bottom: 60px;
  }
  h2 {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 30px;
    text-transform: uppercase;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    font-feature-settings: normal;
    font-optical-sizing: auto;
    font-size: 28px;
    font-size-adjust: none;
    font-stretch: 100%;
    font-style: normal;
    font-variant: normal;
    font-variant-alternates: normal;
    font-variant-caps: normal;
    font-variant-east-asian: normal;
    font-variant-ligatures: normal;
    font-variant-numeric: normal;
    font-variant-position: normal;
    font-variation-settings: normal;
    font-weight: 600;
    line-height: 58px;
    margin-bottom: 0px;
    margin-top: 0px;
    outline-style: none;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    text-align: center;
    text-rendering: optimizelegibility;
    vertical-align: baseline;
    -moz-osx-font-smoothing: grayscale;
    color: var(--heading);
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    /* or 187% */
    letter-spacing: 0.2px;
    color: #2d334a;
    color: #666;
    font-feature-settings: normal;
    font-kerning: auto;
    font-language-override: normal;
    font-optical-sizing: auto;
    font-size: 16px;
    font-size-adjust: none;
    font-stretch: 100%;
    font-style: normal;
    font-variant: normal;
    font-variant-alternates: normal;
    font-variant-caps: normal;
    font-variant-east-asian: normal;
    font-variant-ligatures: normal;
    font-variant-numeric: normal;
    font-variant-position: normal;
    font-variation-settings: normal;
    font-weight: 400;
    line-height: 32px;
    -moz-osx-font-smoothing: grayscale;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 30px;
  }

  .reviews {
    padding-bottom: 30px;
  }

  .review {
    background: white;
    padding: 30px;
    font-style: normal;
    text-align: center;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    .logo {
      width: 75px;
      height: 75px;
      position: absolute;
      top: -45px;
      left: 0;
      right: 0;
      border-radius: 50%;
      background: var(--accent);
      margin: 0 auto;
      border: 10px solid white;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 30px;
        height: 30px;
      }
    }
    h6 {
      padding-top: 30px;
      font-size: 16px;
      line-height: 16px;
      font-weight: 500;
      margin: 0;
      text-transform: uppercase;
    }
    span {
      font-family: 'Montserrat', sans-serif;
      font-size: 14px;
      line-height: 26px;
      color: #999;
      font-weight: 300;
      display: block;
      padding: 5px 0;
    }
    p {
      font-size: 14px;
      line-height: 26px;
      color: #777;
      display: block;
    }

    .stars {
      svg {
        fill: goldenrod;
        height: 14px;
      }
    }
  }
`;

export default function Reviews({ heading, body, reviews }) {
  return (
    <ReviewStyles>
      <div className="container-small heading">
        <h2>{heading}</h2>
        <p>{body}</p>
      </div>

      <div className="container grid reviews">
        {reviews.map((review, i) => (
          <div key={`${i}${new Date().getMilliseconds()}`} className="review">
            <div className="logo">
              <Quote />
            </div>

            <div className="review-header">
              <h6 className="name">{review.name}</h6>
              <span className="position">{review.company}</span>
              <p>{review.body}</p>
            </div>

            <div className="stars">
              <AiFillStar />
              <AiFillStar />
              <AiFillStar />
              <AiFillStar />
              <AiFillStar />
            </div>
          </div>
        ))}
      </div>
    </ReviewStyles>
  );
}

Reviews.propTypes = {
  heading: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  reviews: PropTypes.array.isRequired,
};
