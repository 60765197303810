import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { FaChevronRight } from 'react-icons/fa';

const CardStyles = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 0;
  text-align: left;
  transition: 0.3s;
  overflow: none;

  .card-image {
    width: 100%;
    max-width: 100%;
    height: 100%;
    min-height: 400px;
  }

  .card-image-top {
    width: 100%;
    max-width: 100%;
    height: 130px;
    background-position: center;
    background-size: cover;
    /* border-top-left-radius: calc(.25rem - 1px);
        border-top-right-radius: calc(.25rem - 1px); */
  }

  .card-image-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: calc(0.25rem - 1px);
    h3 {
      color: white;
      font-size: 18px;
      text-transform: uppercase;
    }
    p {
      color: white;
    }

    .card-content {
      padding: 15px;
    }

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .card-body {
    flex: 1 1 auto;
  }

  .card-title {
    font-size: 18px;
    margin-bottom: 15px;
  }

  p {
    font-size: 14px;
    margin-top: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 27px;
    /* or 193% */
    letter-spacing: 0.2px;
  }

  .card-link {
    text-decoration: none;
    text-transform: uppercase;
    z-index: 99;
    background: #f5f5f7;
    transition: ease-in-out 0.3s;
    display: flex;
    justify-content: space-between;
    font-size: 14px;

    span {
      padding: 15px;
    }

    .icon {
      background: #e2e2ea;
      padding: 15px;
      transition: ease-in-out 0.3s;
      svg {
        fill: var(--accent);
        transition: ease-in-out 0.3s;
      }
    }
  }

  .card-link:hover {
    color: var(--accent);
    .icon {
      background: var(--accent);
      svg {
        fill: white;
      }
    }
  }
`;

export default function ServiceCard({
  image,
  title,
  content,
  slug,
  bgCard = true,
}) {
  if (bgCard === true) {
    return (
      <CardStyles>
        <div
          className="card-image"
          style={{
            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('${image}')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        />

        <div className="card-image-overlay">
          <div className="card-content">
            <h3 className="card-title">{title || 'title here'}</h3>
            {content ? <p>{content}</p> : null}
          </div>
          <Link to={`/${slug.current}`} className="card-link">
            <span>Find Out More</span>

            <div className="icon">
              <span>
                <FaChevronRight />
              </span>
            </div>
          </Link>
        </div>
      </CardStyles>
    );
  }
}

ServiceCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  slug: PropTypes.object.isRequired,
  bgCard: PropTypes.bool,
};
