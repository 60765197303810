// Backend really needs to be changes from static material
// into an arrray of services so this can be dynamic to how many the client wants

import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import styled from 'styled-components';

import Coverage from '../../images/coverage.svg';
import Delivery from '../../images/delivery.svg';
import Engineer from '../../images/engineer.svg';
import Managed from '../../images/managed.svg';

import CallToAction from '../CallToAction';

const FeatureStyles = styled.section`
  width: 100vw;
  padding: 60px 0;
  background: white;

  .feature-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 50px;
  }

  .feature {
    text-align: center;

    .hr {
      max-width: 15%;
      margin-bottom: 10px;
      background-color: var(--lightGrey);
      height: 2px;
      margin: 0 auto 10px auto;
    }

    h3 {
      font-size: 14px;
      margin-top: 0;
      padding-bottom: 15px;
      margin-bottom: 0;
      text-transform: uppercase;
      color: var(--heading);
    }

    p {
      font-size: 14px;
      margin-top: 0;
      font-style: normal;
      font-size: 16px;
      line-height: 27px;
      text-align: center;
      letter-spacing: 0.2px;
    }
  }

  .icon-wrapper {
    background: #f5f5f5;
    width: 95px;
    height: 95px;
    margin: 0 auto;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    svg {
      height: 50px;
    }
  }

  .call-to-action {
    padding-top: 30px;
    text-align: center;
  }
`;

const FEATUREQUERY = graphql`
  {
    homepage: sanityHomepage {
      features {
        f1title
        f1tagline
        f2title
        f2tagline
        f3title
        f3tagline
        f4title
        f4tagline
      }
    }
  }
`;

export default function Featues() {
  const { homepage } = useStaticQuery(FEATUREQUERY);
  return (
    <FeatureStyles>
      <div className="container feature-grid">
        <div className="feature">
          <div className="icon-wrapper">
            <Coverage />
          </div>
          <h3>{homepage.features.f1title}</h3>
          <div className="hr" />
          <p>{homepage.features.f1tagline}</p>
        </div>

        <div className="feature">
          <div className="icon-wrapper">
            <Delivery />
          </div>
          <h3>{homepage.features.f2title}</h3>
          <div className="hr" />
          <p>{homepage.features.f2tagline}</p>
        </div>

        <div className="feature">
          <div className="icon-wrapper">
            <Engineer />
          </div>
          <h3>{homepage.features.f3title}</h3>
          <div className="hr" />
          <p>{homepage.features.f3tagline}</p>
        </div>

        <div className="feature">
          <div className="icon-wrapper">
            <Managed />
          </div>
          <h3>{homepage.features.f4title}</h3>
          <div className="hr" />
          <p>{homepage.features.f4tagline}</p>
        </div>
      </div>

      <div className="call-to-action">
        <Link to="/about">
          <CallToAction>About us</CallToAction>
        </Link>
      </div>
    </FeatureStyles>
  );
}
