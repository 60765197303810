import React from 'react';
import PropTypes from 'prop-types';
import { geoMercator } from 'd3-geo';
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from 'react-simple-maps';
import styled from 'styled-components';
import combinedMap from '../../data/combined.json';

export default function Map({ locations }) {
  const projection = geoMercator()
    .center([-2.6178, 55.3781])
    .scale(2700)
    .translate([425, 210]);

  return (
    <MapStyles projection={projection}>
      <Geographies geography={combinedMap}>
        {({ geographies }) =>
          geographies.map((geo) => (
            <Geography
              key={geo.rsmKey}
              geography={geo}
              fill="var(--grey)"
              stroke="#D6D6DA"
              strokeWidth="0.5"
            />
          ))
        }
      </Geographies>

      {locations.map((place, index) => (
        <MarkerStyles
          key={index}
          coordinates={[place.longitude, place.latitude]}
        >
          <circle r={8} fill="var(--accent)" />
          <text textAnchor="middle" y={-10} x={10} fontSize="30px">
            {place.name}
          </text>
        </MarkerStyles>
      ))}
    </MapStyles>
  );
}

Map.propTypes = {
  locations: PropTypes.array,
};

const MapStyles = styled(ComposableMap)`
  height: 100%;
  width: 100%;
  margin: 0 auto;
`;

const MarkerStyles = styled(Marker)`
  text {
    opacity: 0;
    fill: var(--accent);
    z-index: 100;
    transition: ease-in-out 0.3s;
    font: 'Montserrat', sans-serif !important;
    font: normal 30px 'Montserrat';
    pointer-events: none;
  }
  &:hover {
    text {
      opacity: 1;
    }
  }
`;
